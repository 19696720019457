<template>
  <div id="home">
    <link href="https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css" rel="stylesheet" />
    <capa-apps/>
  </div>
</template>

<script>
import CapaApps from "@/components/CapaApps.vue";

export default {
  name: "PrincipalView",
  components: {
    CapaApps,
  },
};
</script>