<template>
  <v-container class="bg pl-10 pt-10">
      
    <div class="text-h5 font-weight-medium">
      Tabela de Contatos
    </div>

    <v-flex style="overflow: auto; max-height: 100vh;">
      <v-data-table :headers="headers" :items="items">
      </v-data-table>
    </v-flex>

  </v-container>
</template>


<style>
.bg {
  background: url('https://img.freepik.com/fotos-gratis/fundo-branco-elegante-com-linhas-de-ondas-azuis_1017-32741.jpg?w=1060&t=st=1678479362~exp=1678479962~hmac=132a4d19a5d06e5ae31014b50c9b67cb9111f67259e6f5d55b263ec90c128e1a') no-repeat right top;
  background-size: cover;
  background-color: blue;
}
.gray-scale {
  filter: grayscale(100%);
}
</style>


<script>

export default {
  data() {
    return {
      headers: [
        { "index": 0, "text": "DS", "value": "ds" },
        { "index": 1, "text": "NOME", "value": "nome" },
        { "index": 2, "text": "SITUAÇÃO", "value": "sit" },
        { "index": 3, "text": "1º TURNO", "value": "turno1" },
        { "index": 4, "text": "TELEFONE", "value": "tel" },
        { "index": 5, "text": "VOTO", "value": "voto" },
        { "index": 6, "text": "RESPONSAVEL", "value": "resp" },
        { "index": 7, "text": "OBSERVAÇÃO", "value": "obs" },
      ],
      items: [
        { ds:'RIBEIRAO PRETO - SP', nome:'ADRIANA OBRECHT', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ADRIANO JOSE DALLA MARTHA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'AILTON PEREIRA DE LIMA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ALARICO SOARES DE SOUZA E MELLO', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ALCIDES SOARES DE AGUIAR', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ALEXANDRE CARLOS GUIMARAES', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ANA PAULA GERVASIO SILVEIRA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ANGEL NASSER TRITTO', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ANGELA MARIA BADAN BETIOLI', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ANGELO SANCHES BISCAINO', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ANTONIA ROSALINA PEREIRA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ANTONIO CARLOS ATULIM', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ANTONIO CARLOS TREVISAN', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ANTONIO WAGNER TOSO', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'APARECIDO MAGALHAES', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ARMANDO VICENTE MEDEIROS BORGES', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'CARLO ALESSANDRO MESQUITA FELIPPINI', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'CARLOS ALBERTO DE SOUZA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'CARLOS ALBERTO SANCHES', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'CARLOS ROBERTO OCCASO', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'CELSO VILELA CHAVES CAMPOS', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'CHRISTIANE LUCATO DUARTE', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'CLARINDA CANDIDA DE JESUS', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'CLAUDETE BURLANDI FEIJO', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'CLAUDIO PEREIRA SILVA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'DALILA KRAUSS DE LIMA MIZUTANI', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'DANIELLE VARGAS GALLETTI', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'DEMILSON JOSE GRELLA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'DENIO PASSALONGO QUINTINO', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'DENISE APARECIDA AGUIAR VILAS BOAS FANTINEL', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'DIONISIO MENDES DOMINGOS', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'EDEVARDE GONCALVES', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'EDILSON LUIZ MOLERO', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'EDUARDO ANTONIO GNATIUC', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'EMANUEL CAMPOS DA SILVEIRA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'EMERSON CATURELI', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'EMILIO CLAUDIO DE OLIVEIRA TIEPPO', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ERNESTO SENISE', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'EURIPEDES BARSANULPHO FERREIRA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'FABIO FERNANDES DE OLIVEIRA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'FABIO MAURICIO VERRI', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'FERNANDO CESAR FIOCO', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'FERNANDO CESAR NEGRAO ROSSI', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'FERNANDO QUEIROZ DE ASSUNCAO', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'FLAVIO DIAS', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'FLAVIO DO CARMO DE CASTRO SILVA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'FLAVIO VILELA CAMPOS', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'FRANCISCO CESAR DE OLIVEIRA SANTOS', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'FRANCISCO SERGIO NALINI', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'GEDIR SILVA DE SOUZA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'GEZIEL CENI DE ALMEIDA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'GILBERTO OLIVEIRA MACEDO', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'GISELA MARCIA FAVERO DA SILVA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'GLAUCO PETER ALVAREZ GUIMARAES', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'GUARACI NEMER', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'HEITOR CHAUD', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'IARA CRISTINA DE PAULA BRONZI SILVA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'IOSHITO FUGITA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ISIS KAROLINE DOS SANTOS', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JAIR TOLENTINO DA SILVA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOAO FRANCISCO SAMPAIO GARCIA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOAO PEDRO DE DEUS', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOEL JOSE MAMEDE DOS SANTOS', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JORGE BATISTA NASCIMENTO', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOSE ADAO VITORINO DE MORAIS', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOSE ANTONIO DE AZEVEDO', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOSE ANTONIO GONCALVES', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOSE CESAR AGOSTINHO COSTA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOSE DE ALMEIDA MARTINS', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOSE EDUARDO MANZINI DE LARA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOSE EUSTACHIO DE LIMA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOSE EVALDO ANTUNES DE MIRANDA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOSE GABELONI', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOSE GERALDO DE OLIVEIRA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOSE MANOEL POLACCHINI', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOSE MARCIO DE ANDRADE FERRARI', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOSE PEDRO FERREIRA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOSEFA CARMONA OCANA DOS SANTOS', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOSELINO MACHADO DE ANDRADE', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JOSINO FERNANDES DE SOUSA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JULIA EGAMI DA COSTA VAL', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'JULIO ALFREDO HAHN CURVO', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'KATIA KATSUMI SAKOMURA DE MATOS', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'LUCINDO VACILOTTO FILHO', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'LUIS CLAUDIO SANTOS', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'LUIS FELIPE CARNAVAL PEREIRA DA ROCHA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'LUIS FERNANDO MAYOR DA SILVA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'LUIS ORLANDO ROTELLI REZENDE', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'LUIS RODOLFO FLEURY CURADO TROVARELI', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'LUIS SALES BARBOSA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'LUIS SERGIO BORGES FANTACINI', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'LUIZ CLAUDIO ALVES PEREIRA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'LUZIA BERNADETE LUCAS DE FARIA VIDEIRA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MAISONETTE PEREIRA BRITTES DE MATTOS', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MANOEL RODRIGUES MOREIRA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARCELA BRASIL DE ARAUJO NOGUEIRA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARCELA CHEFFER BIANCHINI', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARCELO DE CAMARGO FERNANDES', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARCELO DOMINGUEZ DA SILVA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARCELO KAWAKAMI DE REZENDE', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARCELO OTOSHI DE ALMEIDA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARCELO PORTO RODRIGUES', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARCIO LIMA PEOTTA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARIA CRISTINA PERDIGAO DE CARVALHAES NAVES', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARIA DA GLORIA DOS SANTOS', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARIA DO CARMO LOPES E SILVA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARIA HELENA DA SILVA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARIA INES SILVEIRA DE MORAES AGNOLLITTO', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARIA LUCIA DEL LAMA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARIA LUIZA VILAR DE CASTRO', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARIA ROSA BOTTER DE AMORIM', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARIA STELA KRAUSS DE LIMA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARIANA RAMOS FERNANDES', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARILENE MENDES OLIVERIO', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARINA SAMPAIO GUTIERREZ', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARISETE MARQUES PAVAN', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MARLENE APARECIDA CASTIGIO', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MAURY MARINS BRAVO', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MIGUEL DE SOUZA AMADO', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MILTON DA CRUZ', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'MUNIR JOSE DAVID', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'NELSON CAZAROTTI', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'NILTO PELA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'OLIVIA CHAGAS MOTTA', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'OSWALDO DE SEIXAS', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'PAULO ROBERTO LARA DOS SANTOS', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'PAULO ROBERTO TORRES', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'PAULO SERGIO DE CASTRO BELCHIOR JUNIOR', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'REGINA LUCIA RODRIGUES', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'REGINA MARIA DE PAULA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'RENATA CARVALHO DE SOUZA BONETTI', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'RENATO GUGELMIN', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'RITA MARIA GAONA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ROBERTO AUGUSTO FERREIRA DE BARROS GALVAO FILHO', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ROBERTO LUCHIARI DA SILVA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ROBERTO MARTINS LANDIM', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'RODRIGO EUGENIO VENUSO GALLI', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ROGERIO ANTONIO MALINI', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ROSANGELA RIZZI TRINTA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'RUBENS MAURICIO CARVALHO', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'RYOKI KUBA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'SANDRA MARIA ORTEGA OTERO', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'SANDRA RIBEIRO', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'SERGIO EDUARDO BARRETO MAYR', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'SERGIO RENE MARTINEZ', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'SILVIA MENDES MACEDO MARQUES DE ALMEIDA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'SILVIO CESAR DO NASCIMENTO', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'SONIA RODRIGUES JORGE DE SOUZA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'ULISSES PANAYOTIS VOULGARIS', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'VANIA LUCIA DA CUNHA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'WALTER MIRANDA DE ALMEIDA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'WILLIAN DARWIN JUNIOR', sit:'Ativo', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
        { ds:'RIBEIRAO PRETO - SP', nome:'WILMA ZOCCOLARO BARBOSA', sit:'Aposentado', turno1:'', tel:'', voto:'A CONTATAR', resp:'', obs:'', },
      ],
    };
  },
};

</script>