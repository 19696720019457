<template>
  <div id="home">
    <link href="https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css" rel="stylesheet" />
    <contato-table/>
  </div>
</template>

<script>
import ContatoTable from "@/components/ContatoTable.vue";

export default {
  name: "ContatoView",
  components: {
    ContatoTable,
  },
};
</script>